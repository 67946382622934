import { Fragment } from "react";

const FailurePaymentView = () => {

    return(
        <Fragment>
            <h1>Failure</h1>
        </Fragment>
    )
}
export default FailurePaymentView;