import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import useStorage from "../hooks/useStorage";
import checkRedirect from "../tools/checkRedirect";
import ParseTimeToDate from "../tools/dates";
import TicketView from "../views/ticket/TicketView"
import { Helmet } from 'react-helmet'

const Ticket = () => {
    const { folio } = useParams();
    const [searchParams] = useSearchParams();
    const { paymentInfo, ticketInfo, cleanTicket } = useStorage();
    const [payLimit, setPayLimit] = useState('');
    const { redirect } = checkRedirect();

    let source = null;
    if(searchParams.get("source") !== null && searchParams.get("source") !== ''){
        source = searchParams.get("source");
    }

    let payment = null;
    if(searchParams.get("payment") !== null && searchParams.get("payment") !== ''){
        payment = searchParams.get("payment");
    }

    let service = null;
    if(searchParams.get("service") !== null && searchParams.get("service") !== ''){
        service = searchParams.get("service");
    }

    const goToCheckout = () => {
        cleanTicket();
        if(paymentInfo !== null){
            redirect(folio, 'checkout', service, source, payment, null);
        } else {
            redirect(folio, 'init', service, source, payment, null);
        }
    }

    useEffect(() => {
        if(paymentInfo === null || ticketInfo === null){
            goToCheckout();
        }

        if(paymentInfo !== null){
            setPayLimit(ParseTimeToDate(new Date(paymentInfo.payday_limit)));
        }
    }, []);


    if(ticketInfo !== null && paymentInfo !== null){
        return <Fragment>
                <Helmet>
                    <title>{`¡Hola ${paymentInfo.customer}!. Has recibido una solicitud de cobro de ${paymentInfo.merchant}`}</title>
                </Helmet>
                <TicketView goToCheckout={goToCheckout} ticketInfo={ticketInfo} paymentInfo={paymentInfo} payLimit={payLimit}/>
        </Fragment>
    } else {
        return ''
    }
}

export default Ticket
