import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import useApi from "../hooks/useApi";
import useStorage from "../hooks/useStorage";
import checkRedirect from "../tools/checkRedirect";
import PaymentFormsView from "../views/checkout/PaymentFormsView";
import { Helmet } from 'react-helmet'

const PaymentForms = ({toast}) => {

    const { folio } = useParams();
    const [searchParams] = useSearchParams();
    const { paymentInfo, ticketInfo, saveTicketInfo, savePaymentInfo, cleanStorage } = useStorage();
    const [showDetails, setShowDetails] = useState(false);
    const { getDetails } = useApi();
    const { redirect } = checkRedirect();

    let source = null;
    if(searchParams.get("source") !== null && searchParams.get("source") !== ''){
        source = searchParams.get("source");
    }

    let payment = null;
    if(searchParams.get("payment") !== null && searchParams.get("payment") !== ''){
        payment = searchParams.get("payment");
    }

    let service = null;
    if(searchParams.get("service") !== null && searchParams.get("service") !== ''){
        service = searchParams.get("service");
    }

    const showToast = (message, type) => {
        if(type === 'info'){
            toast.info(message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored"
            });
        } else if(type === 'error'){
            toast.error(message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored"
            });
        }
    }

    const selectSource = (paymentSource) => {
        showToast('Generando referencia, ¡por favor espere!', 'info');

        // HACER PETICIÓN
        getDetails({
            source: paymentSource.id,
            payment: payment,
            service: service,
            folio: folio
        }).then(response => {
            savePaymentInfo(response.data.detail);
            saveTicketInfo(response.data.detail.referece);

            if(response.data.detail.payment_link === ''){
                if(response.data.detail.referece !== null){
                    redirect(folio, 'ticket', service, source, payment, null);
                }
            } else {
                if(response.data.detail.payment_link.split('/').length > 1){
                    redirect(null, null, null, null, null, response.data.detail.payment_link);
                } else {
                    toast.dismiss();
                    showToast(`(${response.data.detail.payment_link}). Ocurrió un error con la forma de pago, inténtelo más tarde`, 'error');
                }
                
            }
          })
          .catch(error => {
            if (error.response) {
                showToast(error.response.data.message, 'error');
            } else if (error.request) {
                // console.log("error.request: ", error.requesterror.request);
                showToast('Ocurrió un error inesperado. Vuelve a intentarlo en unos minutos', 'error');
            }
          });;
    }

    const goToInit = () => {
        cleanStorage();
        if(paymentInfo !== null){
            redirect(folio, 'checkout', service, source, payment, null);
        } else {
            redirect(folio, 'init', service, source, payment, null);
        }
    }

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    }

    useEffect(() => {
        if(paymentInfo === null){
            goToInit();
        }
    }, []);

    if(paymentInfo !== null){
        if(paymentInfo.comission > 0){
            setShowDetails(true);
        }
        return <Fragment>
            <Helmet>
                <title>{`¡Hola ${paymentInfo.customer}!. Has recibido una solicitud de cobro de ${paymentInfo.merchant}`}</title>
            </Helmet>
            <PaymentFormsView selectSource={selectSource} paymentInfo={paymentInfo} ticketInfo={ticketInfo} showDetails={showDetails} toggleDetails={toggleDetails} />
        </Fragment>
    } else {
        return '';
    }
}

export default PaymentForms;