const ParseTimeToDate = (payDayLimit) => {
    let dia
    if (payDayLimit.getDate() < 10) {
        dia = '0' + payDayLimit.getDate()
      } else {
        dia = payDayLimit.getDate()
      }
      let mes
      if (payDayLimit.getMonth() + 1 < 10) {
        mes = '0' + (payDayLimit.getMonth() + 1)
      } else {
        mes = payDayLimit.getMonth() + 1
      }
      let horas
      if (payDayLimit.getHours() < 10) {
        horas = '0' + payDayLimit.getHours()
      } else {
        horas = payDayLimit.getHours()
      }
      let minutos
      if (payDayLimit.getMinutes() < 10) {
        minutos = '0' + payDayLimit.getMinutes()
      } else {
        minutos = payDayLimit.getMinutes()
      }
      let segundos
      if (payDayLimit.getSeconds() < 10) {
        segundos = '0' + payDayLimit.getSeconds()
      } else {
        segundos = payDayLimit.getSeconds()
      }
      return `${dia}/${mes}/${payDayLimit.getFullYear()} ${horas}:${minutos}:${segundos}`
}

export default ParseTimeToDate