import '../../views/errors/Error.css';

const Error403 = () => {

    return (
        <div class="main-content">
            <div class="row">
                <div class="text-center text-error-center">
                    <h1 class="titulo-error">403</h1>
                    <h1 class="subtitle-error">El formato del enlace de cobro es incorrecto</h1>
                </div>
                <div class="progress_bar mb-4">
                    <div class="progress_bar_value"></div>
                </div>
            </div>
        </div>
    )
}

export default Error403
