import { Fragment } from "react"
import './PaymentFormsView.css';
import '../../style.css';
import logo1 from '../../assets/img/logo1.jpg';
import getAmount from "../../tools/parseAmount";
// import '../../assets/css/bootstrap.min.css';

const PaymentFormsView = ({selectSource, paymentInfo, ticketInfo, showDetails, toggleDetails}) => {

    let sourceId = '';
    if(ticketInfo !== null){
        sourceId = ticketInfo.id;
    }

    return(
        <Fragment>
            <div id="main">
                <article className="bg-detalle">
                    <div className="textosaludo">
                    <img className="logo show-on-mobile" src={logo1} alt="" />
                    <p className="titulo34">¡Hola <b>{paymentInfo.customer}</b>!</p>
                    <p className="titulo21">Has recibido una solicitud de cobro de <b>{paymentInfo.merchant}</b> por el motivo de <b>{paymentInfo.reason}</b> </p>
                    <br/>
                    <div className="titulopago">
                        <p className="titulo20"><b>¿Cómo te gustaría pagar?</b></p>
                    </div>
                    {paymentInfo.sources.map((source) => {
                        return (
                            <div className="contenedor" key={`S-${source.id}`}>
                            <div id="btn_forma_pago" className={`botoncontainer ${source.icon === 'spei' ? "botonesspei" : source.icon === 'cash' ? "botonesox" : source.icon === 'bank' ? "botonesbank" : source.icon === 'payonpickup' ? "botonescash" : source.icon === 'card' ? "botonestj" : "botonespy"}`} onClick={() => selectSource(source)}>
                                <div className="flex_titulo">
                                    <div className={`noselect tituloforma ${source.icon === 'spei' ? 'tituloforma2' : source.icon === 'payonpickup' ? 'tituloforma3' : ''}`}>{source.name}</div>
                                </div>
                                <div className="flex_img">
                                    <img className="img_pago" src={`../../assets/img/${source.icon}.png`} />
                                </div>
                                </div>
                                {source.id === sourceId && <i className="flex_icon fa fa-check-circle"></i>}
                            </div>
                        )
                    })}
                    </div>


                </article>
                <aside className="bg-detalle">
                    <div>
                    <img className="logo show-on-web" src={logo1} alt="" />
                    </div>
                <section className="bg-section">
                    <div className="flexContainer">
                    <div>
                        <a className="titulodetalle noselect" onClick={() => toggleDetails()}><b className="text_titulodetalle">Detalle de compra<span className={`fa customIcon ${showDetails === false ? 'fa-chevron-down': 'fa-chevron-up'}`}></span></b></a>
                    </div>
                    </div>
                    <div>
                    {showDetails && <table className="tabla_pago">
                        <tbody>
                        <tr>
                            <td className="td_th_tabla td_subtitle"><b className="text_subtitle">Subtotal:</b></td>
                            <td className="td_th_tabla td_title"><b className="text_title">{getAmount(paymentInfo.amount, paymentInfo.currency)}</b></td>
                        </tr>
                        <tr>
                            <th className="td_th_tabla td_subtitle"><b className="text_subtitle">Comision:</b></th>
                            <td className="td_th_tabla td_title"><b className="text_title">{getAmount(paymentInfo.comission, paymentInfo.currency)}</b></td>
                        </tr>
                        </tbody>
                    </table>}
                    <hr className="hr_style"/>
                    <table className="tabla_pago">
                        <tbody>
                        <tr>
                            <th className="td_th_tabla td_subtitle"><b className="text_subtitle">Total:</b></th>
                            <td className="td_th_tabla td_title"><b className="text_title">{getAmount(paymentInfo.total, paymentInfo.currency)}</b></td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="botonespacio">
                        {sourceId !== '' && <button className="btn_continuar"><b className="text_btn_continuar">Continuar</b></button>  }
                        {sourceId === '' && <br/> }
                    </div>
                    </div>
                </section>
                </aside>
            </div>
        </Fragment>
    )
}
export default PaymentFormsView