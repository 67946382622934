import PaymentForms from "./components/PaymentForms";
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Error403 from "./components/errors/Error403";
import Error404 from "./components/errors/Error404";
import Loading from "./components/Loading";
import Ticket from "./components/Ticket";
import { ToastContainer, toast } from 'react-toastify';
import { Fragment } from "react/cjs/react.production.min";
import SuccessfulPayment from "./components/SuccessfulPayment";
import FailurePayment from "./components/FailurePayment";

class App extends Component {
    constructor(){
        super();
    }

    render(){
        return(
            <Fragment>
                <Router>
                <Routes>
                    <Route
                        exact
                        path="/"
                        // element={<SuccessfulPayment />}/>
                         element={<Error403 />}/>
                    <Route
                        exact
                        path="/:folio/init"
                        element={<Loading toast={toast} />}/>
                    <Route
                        exact
                        path="/:folio/checkout"
                        element={<PaymentForms toast={toast} />}/>
                    <Route
                        exact
                        path="/:folio/ticket"
                        element={<Ticket toast={toast} />}/>
                    <Route
                        exact
                        path="/:folio/success"
                        element={<SuccessfulPayment toast={toast} />}/>
                    <Route
                        exact
                        path="/:folio/error"
                        element={<FailurePayment toast={toast} />}/>
                    <Route
                        exact
                        path="*"
                        element={<Error404 />}/>
                </Routes>
            </Router>
            <ToastContainer />
            </Fragment>
        )
    }
}

export default App