import { Fragment } from "react";
import logo1 from '../../assets/img/logo1.jpg';
import './Loading.css';

const LoadingView = () => {

    return(
        <Fragment>
            <div className="main-content">
                <div className="row">
                    <div className="text-center logo-center">
                        <img src={logo1} width="350px" />
                    </div>
                    <div className="progress_bar mb-4">
                            <div className="progress_bar_value"></div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LoadingView;