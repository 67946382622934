import { useState } from "react";

const useStorage = () => {
    const [paymentInfo, setPaymentInfo] = useState(() => {
        let info = null;
        if(sessionStorage.getItem('cGF5bWVudEluZm8=') !== null){
            info = JSON.parse(sessionStorage.getItem('cGF5bWVudEluZm8='));
        }
        return info;
    });

    const [ticketInfo, setTicketInfo] = useState(() => {
        let ticket = null;
        if(sessionStorage.getItem('dGlja2V0SW5mbw==') !== null){
            ticket = JSON.parse(sessionStorage.getItem('dGlja2V0SW5mbw=='));
        }
        return ticket;
    });

    const [successInfo, setSuccessInfo] = useState(() => {
        let success = null;
        if(sessionStorage.getItem('c3VjY2Vzc0luZm8=') !== null){
            success = JSON.parse(sessionStorage.getItem('c3VjY2Vzc0luZm8='));
        }
        return success;
    });

    const savePaymentInfo = (payment) => {
        setPaymentInfo(payment);
        sessionStorage.setItem('cGF5bWVudEluZm8=', JSON.stringify(payment));
    }

    const saveTicketInfo = (ticket) => {
        setTicketInfo(ticket);
        sessionStorage.setItem('dGlja2V0SW5mbw==', JSON.stringify(ticket));
    }

    const saveSuccessInfo = (success) => {
        setSuccessInfo(success);
        sessionStorage.setItem('c3VjY2Vzc0luZm8=', JSON.stringify(success));
    }

    const cleanTicket = () => {
        setTicketInfo(null);
        sessionStorage.removeItem('dGlja2V0SW5mbw==');
    }

    const cleanSuccess = () => {
        setTicketInfo(null);
        sessionStorage.removeItem('c3VjY2Vzc0luZm8=');
    }

    const cleanStorage = () => {
        sessionStorage.clear();
        setPaymentInfo(null);
        setTicketInfo(null);
    }


    return {
        paymentInfo,
        ticketInfo,
        successInfo,
        savePaymentInfo,
        saveTicketInfo,
        saveSuccessInfo,
        cleanStorage,
        cleanTicket,
        cleanSuccess
    }
}
export default useStorage;