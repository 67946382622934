import { Fragment } from "react";
import './SuccessView.css'
import check from '../../assets/img/check.png';

const SuccessfulPaymentView = ({print, ids, infoPayment, infoDate}) => {

    // console.log("infoPayment: ", infoPayment);
    
    return(
        <Fragment>
            <div>
                <div className="cont">
                    
                </div>
            </div>
            <div>
                <div className="modal-menu-catalogo noPrint">
                    <div className="style_share">
                        <i className="fas fa-share-alt fa-lg icono_share_pago"></i>
                    </div>
                </div>
                <div className="modal-container-40">
                    <div className="row">
                    <button onClick={e => window.print()}  type="button" className="close col-12 text-right pr-4 noPrint" data-dismiss="modal" aria-label="Close"><i className="fas fa-print fa-sm"></i></button>
                    <img src={check} className="col-4 offset-4 col-xl-2 offset-xl-5 noPrint"/>
                    </div>
                    <div className="texto_exitoso">
                        ¡PAGO EXITOSO!
                    </div>
                    {infoDate !== null && <div className="row">
                        <div className="col-12 mb-2 col-sm-6 text-center text-sm-right text-md-right text-lg-right text-xl-right text-color-principal"><b>{infoDate.date}</b></div>
                        <div className="col-12 mb-2 col-sm-6 text-center text-sm-left text-md-left text-lg-left text-xl-left text-color-principal"><b>{infoDate.hour}</b></div>
                    </div>}
                    {infoPayment !== null && <div className="style_precio">${infoPayment.amount}</div>}
                    {infoPayment !== null && infoPayment.payer !== undefined && <div className="style_nombre">{infoPayment.payer}</div>}
                    {infoPayment !== null && <div className="style_tarjeta">{infoPayment.provider}</div>}
                    <div className="row mt-4 mb-5">
                        <hr className="col-11"/>
                        <div className="col-12 text-center"><b>Comercio:</b></div>
                        {infoPayment !== null && <div className="col-12 text-center pl-0 mb-1">{infoPayment.store}</div>}
                        <div className="col-12 text-center"><b>Descripción:</b></div>
                        {infoPayment !== null && <div className="col-12 text-center pl-0 mb-1">{infoPayment.description}</div>}
                        <div className="col-12 text-center"><b>Referencia:</b></div>
                        {infoPayment !== null && <div className="col-12 text-center pl-0 mb-1">{infoPayment.id}</div>}
                    </div>
                    <div className="botones_style row">
                        <div className="col-12 mb-2 noPrint">
                            <button type="button" onClick={e => window.print()} className="btn_guardar_ticket btn col-12"><i className="fa fa-download mr-2"></i>Guardar</button>
                        </div>
                        {/* <div className="col-12 col-md-6 mb-2 noPrint">
                            <button type="button" className="btn btn-secondarye col-12" data-dismiss="modal">Continuar<i className="fa fa-arrow-right ml-2"></i></button>
                        </div> */}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default SuccessfulPaymentView;