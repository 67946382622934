// import { useNavigate } from "react-router-dom";


const checkRedirect = () => {

    const redirect = (folio, page, service, source, payment, redirect_to_url) => {
        // const navigation = useNavigate();

        let url_redirect = '/';
        if(redirect_to_url !== null){
            url_redirect = redirect_to_url;
        } else {
            url_redirect = `/${folio}/${page}?service=${service}`;

            if(source !== null){
                url_redirect = `${url_redirect}&source=${source}`
            }
            if(payment !== null){
                url_redirect = `${url_redirect}&payment=${payment}`;
            }
        }
        // navigation(url_redirect);
        window.location.href = url_redirect;
    }

    return {
        redirect
    }

}

export default checkRedirect;