import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useApi from "../hooks/useApi";
import useStorage from "../hooks/useStorage";
import checkRedirect from "../tools/checkRedirect";
import LoadingView from "../views/loading/LoadingView"
import { Helmet } from 'react-helmet'
import { Fragment } from "react/cjs/react.production.min";

const Loading = ({toast}) => {
    const { folio } = useParams();
    const [searchParams] = useSearchParams();
    const { paymentInfo, ticketInfo, savePaymentInfo, saveTicketInfo, saveSuccessInfo, cleanStorage } = useStorage();
    const { getDetails, sendPayPalToken, sendCheckoutInfo } = useApi();
    const { redirect } = checkRedirect();

    let source = null;
    if(searchParams.get("source") !== null && searchParams.get("source") !== ''){
        source = searchParams.get("source");
    }

    let payment = null;
    if(searchParams.get("payment") !== null && searchParams.get("payment") !== ''){
        payment = searchParams.get("payment");
    }

    let service = null;
    if(searchParams.get("service") !== null && searchParams.get("service") !== ''){
        service = searchParams.get("service");
    }

    let token = null;
    if(searchParams.get("token") !== null && searchParams.get("token") !== ''){
        token = searchParams.get("token");
    }

    let payerId = null;
    if(searchParams.get("PayerID") !== null && searchParams.get("PayerID") !== ''){
        payerId = searchParams.get("PayerID");
    }

    // CONEKTA
    let checkoutId = null;
    if(searchParams.get("checkout_id") !== null && searchParams.get("checkout_id") !== ''){
        checkoutId = searchParams.get("checkout_id");
    }

    let orderId = null;
    if(searchParams.get("order_id") !== null && searchParams.get("order_id") !== ''){
        orderId = searchParams.get("order_id");
    }

    let paymentStatus = null;
    if(searchParams.get("payment_status") !== null && searchParams.get("payment_status") !== ''){
        paymentStatus = searchParams.get("payment_status");
    }


    const showToast = (message, type) => {
        if(type === 'success'){
            toast.success(message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored"
            });
        } else if(type === 'error'){
            toast.error(message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                // icon: "🚀"
                // theme: "colored"
            });
        }
    }

    useEffect(() => {
        if(token !== null && payerId !== null && source !== null && service !== null){
            // HACER PETICIÓN PAYPAL
            // cleanStorage();
            sendPayPalToken({
                token: token,
                PayerID: payerId,
                folio: folio,
                service: service,
                source: source
            }).then(response => {
                saveSuccessInfo(response.data.info);
                // console.log("CHECAR RESPONSE: ", response.data);
                redirect(folio, 'success', service, source, payment, null);
            }).catch(error => {
                if (error.response) {
                    showToast(error.response.data.message, 'error');
                } else if (error.request) {
                    showToast('Ocurrió un error inesperado. Vuelve a intentarlo en unos minutos', 'error');
                }
            });
        } else if(checkoutId !== null && orderId !== null && service !== null && source !== null){
            // HACER PETICIÓN PAYPAL
            // cleanStorage();
            sendCheckoutInfo({
                checkout_id: checkoutId,
                order_id: orderId,
                folio: folio,
                service: service,
                source: source
            }).then(response => {
                saveSuccessInfo(response.data.info);
                // console.log("CHECAR RESPONSE: ", response.data);
                redirect(folio, 'success', service, source, payment, null);
            }).catch(error => {
                if (error.response) {
                    showToast(error.response.data.message, 'error');
                } else if (error.request) {
                    showToast('Ocurrió un error inesperado. Vuelve a intentarlo en unos minutos', 'error');
                }
            });
        } else {
            let isRedirect = false;
            if(paymentInfo !== null && ticketInfo !== null){
                if(paymentInfo.id === folio){
                    isRedirect = true;
                    redirect(folio, 'ticket', service, source, payment, null);
                }
            } else if(paymentInfo !== null && ticketInfo === null){
                if(paymentInfo.id === folio){
                    isRedirect = true;
                    redirect(folio, 'checkout', service, source, payment, null);
                }
            }

            // console.log("REDIRECT: ", isRedirect);
    
            if(!isRedirect){
                cleanStorage();
                // HACER PETICIÓN
                getDetails({
                    source: source,
                    payment: payment,
                    service: service,
                    folio: folio
                }).then(response => {
                    // console.log("response: ", response);
                    savePaymentInfo(response.data.detail);
                    saveTicketInfo(response.data.detail.referece);
                    if(response.data.detail.payment_link === ''){
                        if(response.data.detail.referece !== null){
                            redirect(folio, 'ticket', service, source, payment, null);
                        } else {
                            redirect(folio, 'checkout', service, source, payment, null);
                        }
                    } else {
                        if(response.data.detail.payment_link !== '401'){
                            redirect(null, null, null, null, null, response.data.detail.payment_link);
                        } else {
                            showToast('Ocurrió un error con la forma de pago', 'error');
                            setTimeout(redirect(folio, 'checkout', service, source, payment, null), 3000);
                        }
                    }
                  })
                  .catch(error => {
                    //   console.log("ERR: ", error);
                    if (error.response) {
                        showToast(error.response.data.message, 'error');
                    } else if (error.request) {
                        showToast('Ocurrió un error inesperado. Vuelve a intentarlo en unos minutos', 'error');
                    }
                  });
            }
        }
    }, []);

    return (
        <Fragment>
            <Helmet>
                <title>Haz recibido una solicitud de cobro</title>
            </Helmet>
            <LoadingView />
        </Fragment>
     )
}

export default Loading
