import { Fragment } from "react"
import './TicketView.css'
import logo2 from '../../assets/img/logo2.png';
import getAmount from "../../tools/parseAmount";


const TicketView = ({goToCheckout, paymentInfo, ticketInfo, payLimit}) => {

    const getId = (reference) => {
        if(reference !== undefined){
            return reference.split(' ').join('');
        }
    }

	// console.log("paymentInfo: ", paymentInfo);
	// console.log("ticketInfo: ", ticketInfo);

	let showTitleRef = true;




    return(
        <Fragment>
            <div className="ticket_view_flexcontainer bg-detalle">
			<div className="div-left">
				<div className="regresar show-on-web_ticket" onClick={() => goToCheckout()}> <i className="fa fa-chevron-left"></i> Regresar</div>
				<div className="ticket_view_caja">
					<p className="title">Instrucciones de pago</p>
					<div className="instrucciones" dangerouslySetInnerHTML={{ __html: '1. Accede a tu banca en línea. <br/>2. Da de alta la CLABE de esta ficha <br/> 3. Realiza la transferencia correspondiente por la cantidad exacta en esta ficha digital, <b>de lo contrario se rechazará el cargo.</b> <br/> 4.Al confirmar tu pago, el portal de tu banco generará un comprobante digital. <b>En el podrás verificar que se haya realizado correctamente.</b><br/>Conserva este comprobante de pago. <br/> 5. Al completar estos pasos recibirás un correo de <b>LocLog </b> confirmando tu pago.' }}></div>
				</div>
			</div>

			<div className="detalles">
				<div className="leyenda">Ficha digital. No es necesario imprimir</div>
				<div className="row">
					<div className="botoncontainer_ticket col-12">
						<div className="flex_img_ticket">
							<img className="img_ticket" src={logo2} />
						</div>
						<div className="flex_titulo_ticket">
							<p className="monto">Monto a pagar<br/><b className="cantidad">{getAmount(paymentInfo.total, paymentInfo.currency)}</b></p>
						</div>
					</div>
					<div className="contenedor_ticket contenedortj_ticket col-12">
                        {ticketInfo.references !== undefined && ticketInfo.references.length > 0 && ticketInfo.references[0].ref !== '' && <div className="titulo_referencias titulo_referencias2">{`${ticketInfo.name}`}</div>}
						{ticketInfo.references !== undefined && ticketInfo.references.length > 0 && ticketInfo.references[0].ref !== '' && ticketInfo.references.map((reference, index) => {
                            return (
                                <Fragment  key={getId(reference.ref)}>
									<div className="referencias"><b className="bank_reference">{reference.bank}</b><br/>{reference.ref}</div>
									<br/>
								</Fragment>
                            )
                        })}
						{ticketInfo.references !== undefined && ticketInfo.references.length > 0 && ticketInfo.references[0].ref === '' && <div className="referencias referencias3">{ticketInfo.name}</div>}
                        {ticketInfo.references === undefined && <div className="referencias referencias3">{ticketInfo.name}</div>}
					</div>
					<br/>
					<div className="contenedor_ticket contenedortj_ticket col-12">
						<div className="titulo_referencias">FECHA LIMITE</div>
						<div className="referencias referencias3">{payLimit}</div>
					</div>
					<div className="pregunta col-12">¿Tienes alguna duda?</div>
					<div className="pregunta info-pregunta col-12 mb-4">Ponte en contacto con <a className="enlace-pregunta" href={`tel:${paymentInfo.merchants_phone}`}>{paymentInfo.merchant}</a></div>
					<br/>
				</div>
			</div>
		    <div className="regresar show-on-mobile_ticket" onClick={e => goToCheckout()}><i className="fa fa-chevron-left"></i> Regresar</div>
		</div>
        </Fragment>
    )
}
export default TicketView