import SuccessfulPaymentView from "../views/ticket/SuccessfulPaymentView";
import { Helmet } from 'react-helmet'
import { Fragment } from "react/cjs/react.production.min";
import Printer, { print } from 'react-pdf-print'
import { useEffect, useState } from "react";

const SuccessfulPayment = ({toast}) => {
    const ids = ['1'];
    const [infoPayment, setInfoPayment] = useState(null);
    const [infoDate, setInfoDate] = useState(null);

    useEffect(() => {
        const payment = sessionStorage.getItem('c3VjY2Vzc0luZm8=');
        if(payment !== null){
            const paymentParsed = JSON.parse(payment);
            setInfoPayment(paymentParsed);
            const fecha = new Date(paymentParsed.createdAt);
            let dia = fecha.getDate();
            let month = fecha.getMonth() + 1;
            let hora = fecha.getHours();
            let minutos = fecha.getMinutes();
            let segundos = fecha.getSeconds();

            if(dia < 10){
                dia = `0${dia}`
            }
            if(month < 10){
                month = `0${month}`
            }
            if(hora < 10){
                hora = `0${hora}`
            }
            if(minutos < 10){
                minutos = `0${minutos}`
            }
            if(segundos < 10){
                segundos = `0${segundos}`
            }

            setInfoDate({
                date: `${dia}/${month}/${fecha.getFullYear()}`,
                hour: `${hora}:${minutos}:${segundos}`
            })
        }
    }, infoPayment);

    return <Fragment>
        <Helmet prioritizeSeoTags>
            <title>¡Tu pago se ha realizado exitosamente!</title>
        </Helmet>
        <Printer>
            <div id={"1"}>
            <SuccessfulPaymentView toast={toast} print={print} ids={ids} infoPayment={infoPayment} infoDate={infoDate} />
            </div>
        </Printer>
    </Fragment>
};

export default SuccessfulPayment;